import React, { Fragment } from 'react'
import { ChevronRightIcon } from '@heroicons/react/solid'
import { StaticImage } from "gatsby-plugin-image"
import { Helmet } from "react-helmet"

import Menu2 from "../components/menu2"
import Footer from "../components/footer"


// markup
export default function Privacy() {
  return (
    <>
    <Menu2 />
    <Helmet title="FACE - Privacy"/>

    <main>
      <div className="relative max-w-7xl mx-auto pt-20 pb-12 px-4 sm:px-6 lg:px-8 lg:py-20">
        <h2 className="text-2xl text-secondary font-bold mb-4">
          Privacy Notice under the EU General Data Protection Regulation
        </h2>
        <p>
          Applicable to current and potential customers and/or subscribers of FOREX ANALYTIX LLC residing in the European Union
          <br/>
          <br/>
          The present privacy notice provides information on how we process and protect your personal data under Regulation 679/2016 of the European Union on the protection of natural persons with regard to the processing of personal data and on the free movement of such data (“GDPR”).
        </p>

        <h2 className="text-2xl text-secondary font-bold mb-4 mt-10">
          1. Who is responsible for processing your personal data?
        </h2>
        <p>
        <pre>
        Company Name:
          FOREX ANALYTIX LLC (the "Company"")
          <br/><br/>
          Address:<br/>
          10869 N. Scottsdale Rd<br/>
          Suite 103, pmb 334<br/>
          Scottsdale, AZ 85254 USA<br/>
          <br/>
          Contact Details:<br/>
          E-mail: support@forexanalytix.com<br/>
          </pre>
        </p>

        <h2 className="text-2xl text-secondary font-bold mb-4 mt-10">
          2. What personal data do we collect and process?
        </h2>
        <p>
        We collect and process the following personal data:

        (a) Your first and last name
        (b) Your e-mail address
        (c) Your phone number
        (d) Your country of residence
        (e) Data that identifies computers or other devices (e.g. mobile phones) you use to connect to the internet, including your Internet Protocol (IP) address
        </p>

        <h2 className="text-2xl text-secondary font-bold mb-4 mt-10">
          3. Where do we collect your personal data from?
        </h2>
        <p>
          We may collect your personal data:

          (a) directly from you in the following cases:
          - when you sign up to our platform
          - when you register for our webinars
          - when you use our website, mobile device applications or our login services
          - in any e-mails you may send to us

          (b) from our services you use

          or

          c) from any devices you use to connect to the internet (e.g. computers, mobile phones), to determine where you access our services from.
        </p>

        <h2 className="text-2xl text-secondary font-bold mb-4 mt-10">
          4. Why do we process your personal data and on what legal basis?
        </h2>
        <p>
        We process the abovementioned personal data (under 2) in accordance with the provisions of the GDPR for the following purposes:
        <br/><br/>
        (a) For the performance of our contractual obligations towards you
        We process your data in order to provide you with the services you have purchased and/or have registered for, as well as for contacting you in relation to these services whenever this may be required.
        <br/><br/>
        (b) For pursuing and safeguarding our legitimate interests
        We may process your personal data where necessary in order to:
        <br/>
        - review, improve and optimize the services we provide to you as well as access thereto, whether through our website or mobile applications
        <br/>
        - assert our legal claims and defenses in the event of a legal dispute
        <br/><br/>
        (c) In order to comply with any legal obligation we may be subject to
        <br/><br/>
        (d) On the basis of your consent
        <br/><br/>
        We shall process your personal data if you have given us your consent for the processing for specific purposes, whenever this is required (e.g. by signing up to our platform and/or free webinar and registering your e-mail so that we can send you our newsletters).
        Consent granted may be revoked at any time by sending us an e-mail at: support@forexanalytix.com. Such revocation shall only have effect for the future and does not affect the lawfulness of any processing activity carried out on the basis of your consent prior to the revocation.
        </p>

        <h2 className="text-2xl text-secondary font-bold mb-4 mt-10">
          5. Who has access to or receives your personal data?
        </h2>
        <p>
        (a) Within the Company, the partners and back end developers have access to your personal data, in order to provide you with the services you have purchased and/or have registered for.
        <br/><br/>
        (b) Outside the Company, your personal data may be transferred to the following persons who are under a duty to maintain the secrecy of your personal information:
        - e-mail service providers,<br/>
        - payment service providers for processing your payments,<br/>
        - customer support service providers and personnel in order to assist you in any matters relating to online access to our services and the use of our website and mobile applications,<br/>
        - IT specialists employed to maintain our IT systems, for the purposes of ensuring that your personal data is safeguarded,<br/>
        - law firms and lawyers whenever required, for pursuing and/or asserting our legal claims and defenses in any legal disputes.
        <br/><br/>
        (c) Public authorities and/or judicial bodies we may be required to transfer your personal data to, by virtue of law or any order from such authorities of bodies.
        </p>

        <h2 className="text-2xl text-secondary font-bold mb-4 mt-10">
          6. Is your personal data transferred to third countries outside the EU?
        </h2>
        <p>
        We may transfer your personal data to third countries outside the EU in the following cases:
        <br/><br/>
        (a) Data is transferred to a country where the European Commission has decided that it ensures an adequate level of protection for your personal data.
        <br/><br/>
        (b) Appropriate safeguards for the protection of your personal data, have been provided by the recipient.
        <br/><br/>
        (c) You have given your consent for the transfer.
        <br/><br/>
        (d) The transfer is necessary for the performance of the Company’s contractual obligation towards you.
        <br/><br/>
        (e) The transfer is necessary in order for the Company to comply with a legal obligation it may be subject to.
        </p>

        <h2 className="text-2xl text-secondary font-bold mb-4 mt-10">
          7. How long do we store your personal data for?
        </h2>
        <p>
        We shall store your personal data for as long you are a client of ours or have an active subscription with us.
        <br/><br/>
        We shall also store your personal data for up to 10 years after you cease being a client or after your subscription becomes inactive in order to:
        <br/><br/>
        (a) respond to a question you may have or address a complaint you made, and
        <br/><br/>
        (b) pursue and/or assert our legal claims and defenses in the event of a legal dispute.
        <br/><br/>
        In the event of any legal actions which have not been concluded during the above 10 year period, the said time period may be extended until the issuance of an irrevocable court judgment.
        <br/><br/>
        If we are required to keep your personal data for a shorter or longer period of time by virtue of a statutory obligation the above time period shall be shortened or extended accordingly.
        <br/><br/>
        After the lapse of the above mentioned time period, your personal data shall either be destroyed or converted into anonymized data, impeding your identification as a data subject and shall only be used for statistical purposes.
        </p>

        <h2 className="text-2xl text-secondary font-bold mb-4 mt-10">
          8. What data protection rights do you have and how can you exercise them?
        </h2>
        <p>
        (a) Right of access:
        <br/><br/>
        You have the right to obtain information from the Company concerning what personal data is being processed and where it has been collected from, the purpose of the processing, the time period for which your personal data will be stored, as well as the intended recipients of your personal data.
        <br/><br/>
        (b) Right to rectification:
        <br/><br/>
        You have the right to request the rectification of your personal data if the data is inaccurate, incomplete or outdated.
        <br/><br/>
        (c) Right to erasure:
        <br/><br/>
        You have the right to request the erasure of your personal data which the Company stores and processes.
        <br/><br/>
        (d) Right to restriction of processing:
        <br/><br/>
        You may request the restriction of the processing of your personal data if you contest the accuracy of your personal data, or if the processing is unlawful.
        <br/><br/>
        (e) Right to object:
        <br/><br/>
        You have the right to object to the processing of your personal data at any time and ask us to stop using your personal data.
        <br/><br/>
        (f) Right to data portability:
        <br/><br/>
        You may request the transmission of your personal data to any other data controller.
        <br/><br/>
        (g) Right to lodge a complaint with the supervisory authority if you believe your personal data is not being protected or used lawfully.
        <br/><br/>
        * Please note the following concerning the exercise of your rights:
        <br/><br/>
        -Requesting the erasure of your personal data, the restriction of the processing as well as objecting thereto, may mean that we may have to cease the provision of our services to you, insofar as the personal data is necessary for the provision of the services.
        <br/><br/>
        -We reserve the right to refuse to satisfy your rights under (c)- (e) above if the data is necessary to pursue our legitimate interests or if any statutory provisions impede us from doing so.
        <br/><br/>
        -The exercise of your rights shall only have effect for the future and shall not affect any processing activities carried out by the Company prior to the exercise of your rights.
        <br/><br/>
        -The exercise of your rights is free of charge. In the event that your request is manifestly unfounded or excessive, we may charge a reasonable fee or refuse to act on your request.
         <br/><br/>
        You may exercise your rights under (a) – (f) above by sending an e-mail to the address: support@forexanalytix.com
        <br/><br/>
        We shall use our best efforts to act on your requests within 30 days after their submission. This 30 day period may be extended for 60 more days if the Company deems necessary, taking into account the complexity of the request. We shall inform you if this is the case within the above mentioned 30 day time period.
        </p>

      </div>
    </main>

    <Footer />
    </>
  )
}
